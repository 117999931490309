<!--The content below is only a placeholder and can be replaced.-->
<div style="text-align:center">
  <h1>
    {{ title }}
  </h1>
  
</div>
<section class="container">
  <div class="calculator-wrapper">
    <div class="display">
      <div *ngIf="result == null">
          <h2 *ngIf="!displayNumber2">{{displayNumber1}}</h2>
          <h2 *ngIf="displayNumber2">{{displayNumber2}}</h2>
      </div>

    </div>
    <section class="button-container">
      <button class="button-0" (click)="setNumber(0)">0</button>
      <button class="button-1" (click)="setNumber(1)">1</button>
      <button class="button-2" (click)="setNumber(2)">2</button>
      <button class="button-3" (click)="setNumber(3)">3</button>
      <button class="button-4" (click)="setNumber(4)">4</button>
      <button class="button-5" (click)="setNumber(5)">5</button>
      <button class="button-6" (click)="setNumber(6)">6</button>
      <button class="button-7" (click)="setNumber(7)">7</button>
      <button class="button-8" (click)="setNumber(8)">8</button>
      <button class="button-9" (click)="setNumber(9)">9</button>
      <button class="button-plus" (click)="swapNumber('+')">+</button>
      <button class="button-minus" (click)="swapNumber('-')">-</button>
      <button class="button-divide" (click)="swapNumber('/')">/</button>
      <button class="button-times" (click)="swapNumber('*')">*</button>
      <button class="button-percent" (click)="percentageLocal('%')">%</button>
      <button class="button-negative" (click)="negative()">+/-</button>
      <button class="button-decimal" (click)="decimal()">.</button>
      <button class="button-equals" (click)="calculate()">=</button>
      <button class="button-clear" (click)="clear()">C</button>
    </section>
  </div>
</section>


