import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CalculationService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }
  
  options = {                                                                                                                                                                                 
    headers: new HttpHeaders('Access-Control-Allow-Origin: *'), 
  };

  getAddtion(number1: number, number2: number) {
    return this.http.get<number>(
      this.apiUrl + "add/" + number1 + "/" + number2, 
      this.options
    );
  }

  getSubtraction(number1: number, number2: number) {
    return this.http.get<number>(
      this.apiUrl + "sub/" + number1 + "/" + number2, 
      this.options
    );
  }

  getDivision(number1: number, number2: number) {
    return this.http.get<number>(
      this.apiUrl + "div/" + number1 + "/" + number2, 
      this.options
    );
  }

  getMultiplication(number1: number, number2: number) {
    return this.http.get<number>(
      this.apiUrl + "multiply/" + number1 + "/" + number2, 
      this.options
    );
  }

  getPercentage(number1: number, number2: number, symbol: string) {
    if (symbol = "n") {
      number2 = 0;
    }
    return this.http.get<number>(
      this.apiUrl + "percent/" + number1 + "/" + number2 + "/" + symbol, 
      this.options
    );
  }
}
